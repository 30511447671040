import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SEO from "@components/SEO";
import Menu from "@components/Menu";
import Layout from "@components/Layout";

const HomePage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout className="home-page">
      <SEO
        title={t("home.title")}
        description={t("home.description")}
        pathname={location.pathname}
      />
      <Menu />
    </Layout>
  );
};

HomePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default HomePage;
